import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { trackEvent } from "../../utils/event-tracker";
import { Form, Row, Col } from "reactstrap";
import { Modal } from "../../components/modal";
import { FormGroup, Label } from "../../components/form-group";
import Input from "../../components/Input";
import { Input as SwitchInput } from "reactstrap";
import { Button } from "../../components/button";
import { SurveyDetails } from "../../api/users/types";

export const TESTID_ENUMS = {
  surveyNameField: "survey-name-input",
  submitButton: "survey-submit-button",
  approved: "survey-approve",
};

export type UpdateSurveyInputs = Omit<SurveyDetails, "author" | "lastUpdatedBy" | "createdDate" | "updatedDate">;

type UpdateSurveyModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: (data: UpdateSurveyInputs) => void;
  loading: boolean;
  surveyId: string;
  surveyName: string;
  description: string;
  linkToSurvey: string;
  estimatedTime: number;
  startDate: string;
  endDate: string;
  approved: boolean;
};

export function UpdateSurveyModal({
  show,
  onHide,
  onSubmit,
  loading,
  surveyId,
  surveyName,
  description,
  linkToSurvey,
  estimatedTime,
  startDate,
  endDate,
  approved,
}: UpdateSurveyModalProps) {
  const [isDirty, setIsDirty] = useState(false);

  const schemaObject = {
    surveyName: yup.string().trim().required("Required"),
    description: yup.string().trim().required("Required"),
    linkToSurvey: yup.string().url().required("Required"),
    estimatedTime: yup.number().positive().integer().required("Required"),
    startDate: yup.date().required("Required"),
    endDate: yup.date().required("Required"),
  };

  const schema = yup.object(schemaObject).required();

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    values: {
      surveyId,
      surveyName,
      description,
      linkToSurvey,
      estimatedTime,
      startDate,
      endDate,
      approved,
    },
  });

  function serializeValues(data: UpdateSurveyInputs) {
    onSubmit(data);
    reset({
      linkToSurvey: "",
      startDate: "",
      endDate: "",
      estimatedTime: 2,
      description: "",
      surveyName: "",
    });
  }

  useEffect(() => {
    if (show) {
      trackEvent({
        name: `update-survey-modal-opened`,
      });
    }
  }, [show]);

  useEffect(() => {
    const subscription = watch(() => setIsDirty(true));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={isDirty}
      onClose={() => {
        setIsDirty(false);
        onHide();
      }}
      header="Update Survey"
    >
      <Form onSubmit={handleSubmit(serializeValues)}>
        <Row>
          <Col xs="8">
            <FormGroup className="without-margin-top">
              <Label htmlFor="surveyName">Survey Name</Label>
              <Controller
                name="surveyName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="surveyName"
                    data-testid={TESTID_ENUMS.surveyNameField}
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup className="without-margin-top">
              <Label htmlFor="estimatedTime">Estimated Time (minutes)</Label>
              <Controller
                name="estimatedTime"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="estimatedTime"
                    type="number"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup className="without-margin-top">
              <Label htmlFor="description">Description</Label>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="description"
                    type="textarea"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup className="without-margin-top">
              <Label htmlFor="linkToSurvey">Link To Survey</Label>
              <Controller
                name="linkToSurvey"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="linkToSurvey"
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup className="without-margin-top">
              <Label htmlFor="startDate">Start Date</Label>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="startDate"
                    type="datetime-local"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup className="without-margin-top">
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Label htmlFor="endDate">End Date</Label>
                    <Input
                      id="endDate"
                      type="datetime-local"
                      {...field}
                      error={error?.message}
                      invalid={!!error}
                      makeRoomForError
                    />
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col xs="4"></Col>
        </Row>
        <Row>
          <Col xs="4" className="mt-4">
            <FormGroup
              switch
              className="form-check-alt form-check-reverse without-margin-top "
              style={{ borderRadius: "20px" }}
            >
              <Label check for="approved">
                Approved
              </Label>
              <Controller
                name="approved"
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <SwitchInput
                    id="approved"
                    name="approved"
                    data-testid={TESTID_ENUMS.approved}
                    type="switch"
                    onBlur={onBlur}
                    invalid={!!error}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="align-items-center mt-4">
          <Col xs="8"></Col>
          <Col xs="4">
            <div className="text-end">
              <Button
                data-testid={TESTID_ENUMS.submitButton}
                name="submit-new-tag-type"
                type="submit"
                disabled={!isValid}
                loading={isSubmitting || loading}
                level="primary"
                theme="darker"
                rounded="pill"
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
