import React, { createContext, PropsWithChildren, useEffect } from "react";
import "intro.js/introjs.css";
import "../App.scss";
import { MsalProvider } from "@azure/msal-react";
import { ApolloProvider } from "@apollo/client";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";

import { QueryClient, QueryClientProvider } from "react-query";
import mixpanel from "mixpanel-browser";

import { msalInstance } from "../utils/helpers/msal-helper";
import ApolloClient from "../apollo/config";
import { AuthenticationWrapper } from "../components/authentication-wrapper";
import { HttpsRedirect } from "../utils/helpers/https-redirect";

// import { ReactQueryDevtools } from 'react-query/devtools';
import reportAccessibility from "../utils/reportAccessibility";
import { env } from "next-runtime-env";
import { useLocation } from "../hooks/use-location";
import { PageLayout } from "./page-layout";
import NextNProgress from "nextjs-progressbar";
import { AuthState, useIsAuthenticated } from "../hooks/use-is-authenticated";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { ToastProvider } from "../components/toast/toast-provider";
import { identifyUser } from "../utils/event-tracker";
import { FeatureFlagsProvider } from "./feature-flags-provider";
import { hash } from "../utils/sha256";
import { useGetUserRoles, UserRoles } from "../hooks/use-get-user-roles";

const NODE_ENV = process.env.NODE_ENV;

if (env("NEXT_PUBLIC_MIXPANEL_ID")) {
  mixpanel.init(env("NEXT_PUBLIC_MIXPANEL_ID") as string, {
    debug: NODE_ENV === "development",
    api_host: env("NEXT_PUBLIC_MIXPANEL_PROXY"),
    // @ts-expect-error api_payload_format is not in the types
    api_payload_format: NODE_ENV !== "production" ? "json" : "base64",
    secure_cookie: true,
    cross_site_cookie: true,
  });
  mixpanel.register({
    app_version: env("NEXT_PUBLIC_DEPLOY_VERSION") ?? "local",
  });
}

if (NODE_ENV === "development") {
  reportAccessibility(React);
}

// We do this because we want to hide the new flag after a delay,
// but if do this in the component and it get's unmounted the component,
// the setTimeout will never fire.
export function hideNewFlagAfterDelay(setIsShowing: (value: boolean) => void) {
  setTimeout(() => setIsShowing(false), 60 * 1_000);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AuthContext = createContext<AuthState & Omit<UserRoles, "_id" | "username">>({
  isAuthenticated: false,
  isDelegate: false,
  isDesignLead: false,
  isOwner: false,
  isTechLead: false,
});

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const auth = useIsAuthenticated();
  const roles = useGetUserRoles();

  return <AuthContext.Provider value={{ ...auth, ...roles }}>{children}</AuthContext.Provider>;
};

export const MsalAuthProvider = ({ children, instance }: PropsWithChildren<{ instance: PublicClientApplication }>) => {
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const canonicalEmail = instance.getAllAccounts()[0].username.trim().toLowerCase();
        identifyUser({
          uniqueId: hash(canonicalEmail),
        });
      }
    });

    // Clean up the event listener on unmount
    return () => {
      instance.removeEventCallback(callbackId as string);
    };
  }, [instance]);
  return (
    <MsalProvider instance={instance}>
      <AuthProvider>
        <AuthenticationWrapper>{children}</AuthenticationWrapper>
      </AuthProvider>
    </MsalProvider>
  );
};

const AppWrapper: React.FC<PropsWithChildren> = (props) => (
  <HttpsRedirect>
    <ApolloProvider client={ApolloClient}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <MsalAuthProvider instance={msalInstance}>
          <DndProvider options={HTML5toTouch}>
            <FeatureFlagsProvider>
              <ToastProvider>{props.children}</ToastProvider>
            </FeatureFlagsProvider>
          </DndProvider>
        </MsalAuthProvider>
      </QueryClientProvider>
    </ApolloProvider>
  </HttpsRedirect>
);

export default function App({ Component, pageProps }: { Component: any; pageProps: any }) {
  const { pathname } = useLocation();

  return (
    <AppWrapper>
      <PageLayout>
        <NextNProgress />
        <Component key={pathname} {...pageProps} />
      </PageLayout>
    </AppWrapper>
  );
}

// Do not remove this snippet. This disables Next.js Automatic Static Optimization.
// When our application moves over to fetching data server-side via `getServerSideProps`,
// this will occur automatically, but at the moment, Next.js thinks it can pre-render
// our pages, which breaks the `UseRouter` hook.
// See here: https://nextjs.org/docs/pages/building-your-application/rendering/automatic-static-optimization
App.getInitialProps = async () => {
  return {};
};
