/* istanbul ignore file */

import { useState, FC, MouseEvent } from "react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { RightSmall32 } from "@bphxd/ds-core-react/lib/icons";
import styled from "@emotion/styled";
import { Dropdown, NavItem, NavItemProps } from "reactstrap";
import { KebabMenu } from "../../components/buttons/kebab-menu";
import { DndTreeData } from "./dnd-tree-types";
import { TESTID } from "./enums";
import { uuidToHref } from "./page-footer";
import { Link } from "../../components/Link";

type Props = {
  node: DndTreeData;
  depth: number;
  isOpen: boolean;
  active: boolean;
  onToggle: (id: NodeModel["id"]) => void;
  hasChild: boolean;
  draggable: boolean;
};

const Root = styled(NavItem)(({ depth, active }: NavItemProps & Pick<Props, "depth" | "active">) => ({
  minHeight: 40,
  paddingInlineEnd: 8,
  paddingLeft: depth * 15,
  ".kebab-icon": {
    visibility: active ? "visible" : "hidden",
  },
  "&:hover": {
    ".kebab-icon": {
      visibility: "visible",
    },
  },
}));

const IconWrapper = styled.div({
  fontSize: 0,
  height: 24,
  width: 24,
  transition: "transform linear 0.1s",
  transform: "rotate(0deg)",
  "&.isOpen": {
    transform: "rotate(90deg)",
  },
});

const ItemLabel = styled(Link)({
  paddingInlineStart: 8,
});

export const NavTreeItem: FC<Props> = (props) => {
  const { isOpen, active, depth, node, hasChild } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleToggle = (e: MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  return (
    <Root
      className={`d-flex align-items-center text-dark ${active ? "bg-tertiary" : undefined}`}
      tag="div"
      data-testid={active ? TESTID.activeNavItem : undefined}
      depth={depth}
      active={active}
    >
      {depth !== 0 && (
        <IconWrapper
          role="button"
          onClick={handleToggle}
          className={"d-flex align-items-center justify-content-center" + (isOpen ? " isOpen" : undefined)}
        >
          <div>
            {hasChild ? <RightSmall32 className="text-secondary" /> : <div style={{ width: 32, height: 32 }}>{""}</div>}
          </div>
        </IconWrapper>
      )}
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="d-flex justify-content-between align-items-center"
        style={{ width: "inherit" }}
      >
        <ItemLabel className="w-100 nav-link" href={uuidToHref(node.id as string)}>
          {node.text}
        </ItemLabel>
        <KebabMenu path={node.data?.path ?? ""} uuid={node.id as string} isOpen={dropdownOpen} />
      </Dropdown>
    </Root>
  );
};
