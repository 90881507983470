import styled from "@emotion/styled";

const fontSize: Record<string, number> = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 28,
  xxl: 36,
};

const iconSizeMap: Record<string, number> = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 40,
  xl: 48,
  xxl: 64,
};

/* setting of width & height */
const getIconSizeMap = ({ size = "md" }) => `${iconSizeMap[size] / 32}rem`;

const getFontSize = ({ size = "md" }) => `${fontSize[size] / 16}rem`;

const getColor = ({ color }: { color?: string }) => color;

const getHover = ({ removeHover }: { removeHover?: boolean }) => {
  return removeHover
    ? ""
    : `
    &:hover {
      color: #283593;
    }
  `;
};

const IconWrapStyled = styled.div`
  position: relative;
  width: ${getIconSizeMap};
  height: ${getIconSizeMap};
`;

type IconStyleProps = Partial<{
  size: string;
  color: string;
  removeHover?: boolean;
  weight?: number;
  Icon: string;
}>;
const IconStyled = styled.div<IconStyleProps>`
  ${getHover};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "bp";
  font-size: ${getFontSize};
  color: ${getColor};
`;

const style = {
  IconStyled,
  IconWrapStyled,
  getIconSizeMap,
  getFontSize,
  getColor,
};

export default style;
