import { useLocation } from "../../hooks/use-location";
import { Form, Row, Col } from "reactstrap";
import { FormGroup, Label } from "../form-group";
import { Button } from "../button";
import { Controller, useForm } from "react-hook-form";
import Input from "../Input";
import { yupResolver } from "@hookform/resolvers/yup";
// import { Datepicker } from '@bphxd/ds-core-react';
import { useEffect, useState } from "react";
import Select from "../Select";
import { GET_GLOBAL_TAGS } from "../../api/faq/gql";
import { useQuery as useGraphQLQuery } from "@apollo/client";
import { useQuery as useRestQuery } from "react-query";
import { TagOption, mapTagsToSelectOptions } from "../../api/common-tags-functionality";
import { fetchDataSources } from "../../api/search/v2";
import { SearchModalFormField } from "./modal-form-field";
import {
  FormValues,
  createAdvancedSearchUrl,
  formSchema,
  getAdvancedSearchModalValuesFromSearchQuery,
} from "./modal-utilities";
import {
  useAppActions,
  useDocumentTypeValues,
  useIncludeRetiredDocs,
  useSearchBarInput,
  useSearchChatId,
} from "../../hooks/use-app-store";
import { AdvancedSearchFilters, KendraFilterValues } from "../../views/search/search-filters";

enum TESTID {
  advancedSearchInput = "advanced-search-input",
  advancedSearchContainsExact = "advanced_search_contains_exact",
  AdvancedSearchIncludeRetiredDocs = "advanced_search_include_retired_content",
}

export function SearchForm() {
  const { setIsSearchFormOpen, setIncludeRetiredDocs, setDocumentTypeValues } = useAppActions();
  const searchBarInput = useSearchBarInput();
  const includeRetiredDocs = useIncludeRetiredDocs();
  const searchChatId = useSearchChatId();
  const { router: history } = useLocation();
  const values = getAdvancedSearchModalValuesFromSearchQuery(searchBarInput);
  const [dateFrom] = useState<Date | undefined>(values.defaultDate?.[0]);
  const [, setDate] = useState<Date[] | undefined>(values.defaultDate);
  const [dateTo] = useState<Date | undefined>(values.defaultDate?.[1]);
  const [allTagOptions, setAllTagOptions] = useState<TagOption[]>([]);
  const [dataSources, setDataSources] = useState<TagOption[]>([]);
  const [loadingDots, setLoadingDots] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const documentTypeOptions: TagOption[] = [
    { value: KendraFilterValues.KDD, label: AdvancedSearchFilters.KDD },
    { value: KendraFilterValues.FAQ, label: AdvancedSearchFilters.FAQ },
    { value: KendraFilterValues.Code, label: AdvancedSearchFilters.Code },
    { value: KendraFilterValues.Automation, label: AdvancedSearchFilters.Automation },
    { value: KendraFilterValues.Documents, label: AdvancedSearchFilters.Documents },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const documentTypeValues = useDocumentTypeValues();

  const { isLoading: dataSourcesAreLoading } = useRestQuery([], async () => {
    const response = await fetchDataSources();
    setDataSources(mapTagsToSelectOptions(response));
  });

  const { loading: globalTagsAreLoading, data: globalTagsQuery } = useGraphQLQuery(GET_GLOBAL_TAGS);

  useEffect(() => {
    const tags = globalTagsQuery?.allTags?.tags;
    setAllTagOptions(mapTagsToSelectOptions(tags));
  }, [globalTagsQuery]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setLoadingDots((prevDots) => (prevDots >= 3 ? 0 : prevDots + 1));
      }, 300);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(globalTagsAreLoading || dataSourcesAreLoading);
  }, [globalTagsAreLoading, dataSourcesAreLoading]);

  async function submitHandler(formValues: FormValues) {
    setIncludeRetiredDocs(formValues.advanced_search_include_retired_content);
    setDocumentTypeValues(formValues.advanced_search_document_type ?? []);
    const advancedSearchUrl = createAdvancedSearchUrl(dateFrom, dateTo, formValues, searchChatId);
    setIsSearchFormOpen(false);
    history.push(advancedSearchUrl);
  }

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(formSchema),
  });

  return isLoading ? (
    <Row className="justify-content-center align-items-center">
      <h3> Loading {Array(loadingDots).fill(".").join("")}</h3>
    </Row>
  ) : (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <SearchModalFormField
          label="Contains these words:"
          formController={
            <Controller
              name="advanced_search_contains"
              control={control}
              defaultValue={values.cleanedSearchFormQuery}
              render={({ field, fieldState: { error } }: any) => (
                <Input
                  {...field}
                  id="advanced_search_contains"
                  data-testid={TESTID.advancedSearchInput}
                  type="text"
                  style={{ resize: "none" }}
                  invalid={!!error}
                  placeholder="Add search terms here"
                />
              )}
            />
          }
          htmlFor="advanced_search_contains"
        />
        <Col xs="4" className="d-flex align-items-center row row-cols-1">
          <FormGroup switch className="mt-0 gap-4">
            <Controller
              name="advanced_search_contains_exact"
              control={control}
              defaultValue={values.isExactPhrase}
              render={({ field, fieldState: { error } }: any) => (
                <>
                  <Input
                    {...field}
                    id="advanced_search_contains_exact"
                    data-testid={TESTID.advancedSearchContainsExact}
                    type="switch"
                    checked={field.value}
                    invalid={!!error}
                  />
                  <Label htmlFor="advanced_search_contains_exact" className="mb-0">
                    Exact phrase
                  </Label>
                </>
              )}
            />
          </FormGroup>
          <FormGroup switch className="mt-0 gap-4">
            <Controller
              name="advanced_search_include_retired_content"
              control={control}
              defaultValue={includeRetiredDocs}
              render={({ field, fieldState: { error } }: any) => (
                <>
                  <Input
                    {...field}
                    id="advanced_search_include_retired_content"
                    data-testid={TESTID.AdvancedSearchIncludeRetiredDocs}
                    type="switch"
                    checked={field.value}
                    invalid={!!error}
                  />
                  <Label htmlFor="advanced_search_include_retired_content" className="mb-0">
                    Include Retired Content
                  </Label>
                </>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      {/*
      TODO: Remove Datepicker component until the bphxd/ds-core-react team resolves it
      https://github.com/bphxd/ds-core-react/issues/827
      */}
      {/*
      <Row>
        <SearchModalFormField
          label="Enter date from and to:"
          formController={
            <Controller
              name="advanced_search_date"
              control={control}
              defaultValue={date?.toLocaleString()}
              render={({ field }: any) => (
                <Datepicker
                  {...field}
                  options={{
                    defaultDate: date,
                    mode: 'range',
                    showMonths: 2,
                    allowInput: false,
                    maxDate: Date.now(),
                    onClose: (selectedDates: Date[], dateString: string) => {
                      field.onChange(dateString);
                      setDateFrom(selectedDates[0]);
                      const selectedDateTo =
                        selectedDates[1] ?? selectedDates[0];
                      setDateTo(selectedDateTo);
                      setDate([selectedDates[0], selectedDateTo]);
                    },
                  }}
                  data-testid="advanced_search_date"
                />
              )}
            />
          }
          htmlFor="advanced_search_date"
        />
        </Row>
        */}
      <Row>
        <SearchModalFormField
          label="Author / Owner:"
          formController={
            <Controller
              name="advanced_search_author"
              control={control}
              defaultValue={values.author ?? ""}
              render={({ field }: any) => (
                <Input {...field} id="advanced_search_author" placeholder="Type an author's name…" />
              )}
            />
          }
          htmlFor="advanced_search_author"
        />
      </Row>
      <Row>
        <SearchModalFormField
          label="Tags:"
          formController={
            <Controller
              name="advanced_search_tags"
              control={control}
              defaultValue={values.tags}
              render={({ field }: any) => (
                <Select
                  {...field}
                  inputId="advanced_search_tags"
                  isMulti={true}
                  className="basic-multi-select bg-white"
                  placeholder="Start typing to search for tags"
                  options={allTagOptions ?? []}
                  isDisabled={isSubmitting}
                />
              )}
            />
          }
          htmlFor="advanced_search_tags"
        />
      </Row>
      <Row>
        <SearchModalFormField
          label="Data sources:"
          formController={
            <Controller
              name="advanced_search_data_sources"
              control={control}
              defaultValue={values.dataSources}
              render={({ field }: any) => (
                <Select
                  {...field}
                  inputId="advanced_search_data_sources"
                  isMulti={true}
                  className="basic-multi-select bg-white"
                  placeholder="Start typing to search for data sources"
                  options={dataSources}
                  isDisabled={isSubmitting}
                />
              )}
            />
          }
          htmlFor="advanced_search_data_sources"
        />
      </Row>
      <Row>
        <SearchModalFormField
          label="Document Type:"
          formController={
            <Controller
              name="advanced_search_document_type"
              control={control}
              defaultValue={documentTypeValues}
              render={({ field }: any) => (
                <Select
                  {...field}
                  inputId="advanced_search_document_type"
                  isMulti={true}
                  className="basic-multi-select bg-white"
                  placeholder="Select a document type..."
                  options={documentTypeOptions}
                  isDisabled={isSubmitting}
                />
              )}
            />
          }
          htmlFor="advanced_search_document_type"
        />
      </Row>
      <Row>
        <Col xs="6" className="d-flex justify-content-start">
          <Button
            data-testid="search-clear-filters"
            name={"search-clear-filters"}
            disabled={isLoading || isSubmitting}
            onClick={() => {
              control._reset({
                advanced_search_author: "",
                advanced_search_contains_exact: false,
                advanced_search_data_sources: [],
                advanced_search_date: "",
                advanced_search_tags: [],
                advanced_search_contains: "",
              });
              setDate([]);
            }}
            rounded="pill"
            theme="standard"
            level="tertiary"
          >
            Reset
          </Button>
        </Col>
        <Col xs="6" className="d-flex justify-content-end">
          <Button
            data-testid={"search-form-submit"}
            name={"search-form-submit"}
            type="submit"
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            size="md"
            rounded="pill"
            level="primary"
            theme="darker"
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
