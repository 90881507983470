import { useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Form, Row, Col, Progress } from "reactstrap";
import { FormGroup } from "../../components/form-group";
import Input, { InputErrorMessage } from "../../components/Input";
import { Button } from "../../components/button";
import { AccelerateTeam, Step1Data } from "./wizard";

type Step1FormProps = {
  teamOptions: AccelerateTeam[];
  isSubmitting: boolean;
  onSubmit: SubmitHandler<Step1Data>;
  onDirty: () => void;
};

export function Step1Form({ teamOptions, isSubmitting, onSubmit, onDirty, ...data }: Step1FormProps) {
  const schemaObject = {
    team: yup.string().required("Required"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<Step1Data>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  useEffect(() => {
    const subscription = watch(() => {
      onDirty();
    });
    return () => subscription.unsubscribe();
  }, [watch, onDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-1">
      <Controller
        name="team"
        control={control}
        render={({ field }) => (
          <>
            {teamOptions.map(({ value, label, description }) => (
              <Row key={value}>
                <Col xs="12">
                  <FormGroup check>
                    <Input
                      {...field}
                      id={`team-${value}`}
                      data-testid={`form-step-1-radio-${value}`}
                      trackingName="help-and-support-team"
                      type="radio"
                      value={value}
                      checked={value === control._formValues.team}
                    />
                    <label htmlFor={`team-${value}`}>
                      <span className="font-normal">{label}</span> - {description}
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            ))}
          </>
        )}
      />

      <Row>
        <Col xs="12">
          <InputErrorMessage>{errors.root?.message}</InputErrorMessage>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs="2"></Col>
        <Col xs="8">
          <div className="m-auto">
            <Progress color="dark" value={25} style={{ height: 2 }} />
          </div>
        </Col>
        <Col xs="2">
          <div className="text-right">
            <Button
              data-testid="form-step-1-submit"
              name="help-and-support-step1-next"
              type="submit"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              level="primary"
              theme="darker"
              rounded="pill"
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
