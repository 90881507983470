import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { trackEvent } from "../../utils/event-tracker";
import { Form, Row, Col } from "reactstrap";
import { FormGroup, Label } from "../../components/form-group";
import Input from "../../components/Input";
import { Button } from "../../components/button";
import { SurveyDetails } from "../../api/users/types";
import { Modal } from "../../components/modal";

const DATA_TESTIDS = {
  typeInput: "survey",
  submitButton: "survey-submit-button",
  approve: "survey-approve",
};

export type AddSurveyInputs = Omit<
  SurveyDetails,
  "surveyId" | "author" | "lastUpdatedBy" | "approved" | "createdDate" | "updatedDate"
>;

type AddSurveyModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: (data: AddSurveyInputs) => void;
  loading: boolean;
};

const defaultValues = {
  linkToSurvey: "",
  startDate: new Date(Date.now()).toISOString(),
  endDate: new Date(Date.now()).toISOString(),
  estimatedTime: 0,
  description: "",
  surveyName: "",
};

export function AddSurveyModal({ show, onHide, onSubmit, loading }: AddSurveyModalProps) {
  const [isDirty, setIsDirty] = useState(false);

  const schemaObject = {
    surveyName: yup.string().trim().required("Required"),
    description: yup.string().trim().required("Required"),
    linkToSurvey: yup.string().url().required("Required"),
    estimatedTime: yup.number().positive().integer().required("Required"),
    startDate: yup.date().required("Required"),
    endDate: yup.date().required("Required"),
  };

  const schema = yup.object(schemaObject).required();

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<AddSurveyInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    values: {
      ...defaultValues,
    },
  });

  function serializeValues(data: AddSurveyInputs) {
    onSubmit(data);
    reset({
      ...defaultValues,
    });
  }

  useEffect(() => {
    if (show) {
      trackEvent({
        name: `add-survey-modal-opened`,
      });
    }
  }, [show]);

  useEffect(() => {
    const subscription = watch(() => setIsDirty(true));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={isDirty}
      onClose={() => {
        setIsDirty(false);
        onHide();
      }}
      header="Add a New Survey"
    >
      <Form onSubmit={handleSubmit(serializeValues)}>
        <Row>
          <Col xs="8">
            <FormGroup className="without-margin-top">
              <Label htmlFor="tagType">Survey Name</Label>
              <Controller
                name="surveyName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="surveyName"
                    data-testid={DATA_TESTIDS.typeInput}
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup className="without-margin-top">
              <Label htmlFor="tagType">Estimated Time (minutes)</Label>
              <Controller
                name="estimatedTime"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="estimatedTime"
                    type="number"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup className="without-margin-top">
              <Label htmlFor="description">Description</Label>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="description"
                    type="textarea"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup className="without-margin-top">
              <Label htmlFor="description">Link To Survey</Label>
              <Controller
                name="linkToSurvey"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="linkToSurvey"
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <FormGroup className="without-margin-top">
              <Label htmlFor="description">Start Date</Label>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="startDate"
                    type="datetime-local"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup className="without-margin-top">
              <Label htmlFor="description">End Date</Label>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="endDate"
                    type="datetime-local"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs="8"></Col>
          <Col xs="4">
            <div className="text-end">
              <Button
                data-testid={DATA_TESTIDS.submitButton}
                name="submit-new-tag-type"
                type="submit"
                disabled={!isValid}
                loading={isSubmitting || loading}
                level="primary"
                theme="darker"
                rounded="pill"
              >
                Add a new survey
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
