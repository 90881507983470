import { useEffect, useState } from "react";
import LZString from "lz-string";
import { useUser } from "../../hooks/use-user";
import { ACCELERATE_APIS } from "../../api/config";
import { requestAccessToken } from "../../utils/helpers/msal-helper";
import { Button } from "../button";
import { ExternalLink24 } from "@bphxd/ds-core-react/lib/icons";

export function ApolloPlayGroundLink() {
  const user = useUser();
  const { isContentAdmin } = user ?? {};
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    if (isContentAdmin) {
      getToken();
    }
  }, [isContentAdmin]);

  const { scopes } = ACCELERATE_APIS.apollo;
  const explorerURLState = LZString.compressToEncodedURIComponent(
    JSON.stringify({
      headers: JSON.stringify({ Authorization: `Bearer ${token}` }),
    }),
  );
  let target = "https://graphql.accelerate.int.bpglobal.com";
  if (window.location.origin === "https://accelerate.bpglobal.com") {
    target = "https://graphql.accelerate.bpglobal.com";
  }

  async function getToken() {
    const bearerToken = await requestAccessToken(scopes);
    if (bearerToken) {
      setToken(bearerToken);
    }
  }

  return token ? (
    <div className="d-flex my-4">
      <Button
        href={`${target}/?explorerURLState=${explorerURLState}`}
        className="d-flex align-items-center"
        target="_blank"
        rel="noreferrer"
        name="apollo-playground-link"
      >
        <span>Go to Apollo playground</span> <ExternalLink24 className="mb-2" />
      </Button>
    </div>
  ) : null;
}
