import { gql } from "@apollo/client";

export const GET_USER_ROLES = gql`
  query UserRoles {
    currentUserProfile {
      _id
      username
      isOwner
      isDelegate
      isTechLead
      isDesignLead
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query CurrentUserProfile {
    currentUserProfile {
      _id
      username
    }
  }
`;

export const CREATE_TEAM_LINKED_RESOURCE = gql`
  mutation createTeamLinkedResource($teamId: String!, $description: String!, $url: String!) {
    createTeamLinkedResource(teamId: $teamId, description: $description, url: $url) {
      description
      url
    }
  }
`;
