type Size = "sm" | "md" | "lg" | "xl";

export function Loader({ size }: { size?: Size }) {
  let width = "2rem";
  switch (size) {
    case "sm":
      width = "1rem";
      break;
    case "md":
      width = "2rem";
      break;
    case "lg":
      width = "3rem";
      break;
    case "xl":
      width = "4rem";
      break;
    default:
      break;
  }
  const style = {
    width,
    height: width,
  };
  return (
    <div className="mx-auto" style={style}>
      <LoadingSpinner />
    </div>
  );
}

export function LoadingSpinner({ color = "" }: { color?: string }) {
  return (
    <div data-testid="loading-spinner" className="d-flex align-items-center">
      <div className="">
        <svg
          className="rotate-360-loop"
          width="100%"
          height="100%"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          strokeLinecap="round"
        >
          <defs>
            <linearGradient id="bpGradient">
              <stop offset="5%" stopColor="#007F00" />
              <stop offset="90%" stopColor="#004F00" />
            </linearGradient>
          </defs>
          <g
            transform="translate(1 1)"
            stroke={color || "url('#bpGradient')"}
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          >
            <circle strokeOpacity="0.6" cx="18" cy="18" r="18"></circle>
            <path d="M36 18c0-9.94-8.06-18-18-18"></path>
          </g>
        </svg>
      </div>
    </div>
  );
}
