import { useQuery } from "@apollo/client";
import { GET_USER_ROLES } from "../api/profiles/gql";

export type UserRoles = {
  _id: string;
  username: string;
  isOwner: boolean;
  isDelegate: boolean;
  isTechLead: boolean;
  isDesignLead: boolean;
};
export function useGetUserRoles() {
  const { data } = useQuery<{
    currentUserProfile: UserRoles;
  }>(GET_USER_ROLES);
  return (
    data?.currentUserProfile ?? {
      isOwner: false,
      isDelegate: false,
      isTechLead: false,
      isDesignLead: false,
    }
  );
}
