import { useState, useEffect } from "react";

import { useUser } from "../../hooks/use-user";
import { useWebsocket } from "../../hooks/use-websocket";
import {
  NEW_NOTIFICATION_ENUM,
  NOTIFICATION_LIST_ENUM,
  REQUEST_NOTIFICATION_LIST_ENUM,
  UPDATE_NOTIFICATION_READ_STATUS_ENUM,
  UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM,
} from "./notification-enums";

import styled from "@emotion/styled";
import { Bell } from "react-feather";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { NotificationList } from "./notification-list";
import { trackEvent } from "../../utils/event-tracker";

const Container = styled.div`
  border-radius: 100%;
  margin: 0 12px;

  .observed-notifications,
  .pending-notifications {
    border: ${({ trayIsOpen }: { trayIsOpen: boolean }) => (trayIsOpen ? "1px solid #232323" : "")};
    width: 42px;
    height: 42px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #111;
    cursor: pointer;
  }
  .pending-notifications {
    background-color: ${({ trayIsOpen }) => (trayIsOpen ? "#f2f2f2" : "rgba(79, 195, 247, 0.1)")};
    position: relative;
    .unread-count {
      position: absolute;
      right: 2px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      max-height: 10px;
      max-width: 10px;
      border: solid 2px #fff;
      border-radius: 50%;
      background-color: #4fc3f7;
      color: white;
      font-size: 9px;
      padding: 2px;
      font-weight: bold;
      float: right;
    }
  }
  .observed-notifications {
    background-color: #f2f2f2;
  }

  .dropdown {
    height: 100%;
  }

  .notification-toggle {
    height: 100%;
    opacity: ${({ trayIsOpen }) => (trayIsOpen ? 1 : 0.64)};
    &:hover {
      opacity: 1;
    }
  }

  .notification-toggle.btn {
    padding: 0px;
    border-radius: 50%;
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
    border-radius: 5px 0 5px 5px;
    border-right: solid 1px #f2f2f2;
    border-bottom: solid 1px #f2f2f2;
    border-left: solid 1px #f2f2f2;
  }

  .notification-tray {
    padding: 24px 18px;
    width: 350px;
    height: 360px;
  }
  .notification-tray__heading {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.1px;
    color: #111;
  }
  .notification-tray__list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    height: 295px;
    overflow: scroll;

    button {
      padding: 0;
      margin-bottom: 8px;
    }
  }
  .no-messages,
  .notification-message {
    font-size: 16px;
  }

  .no-messages {
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    bottom: 2rem;
    pointer-events: none;
  }

  .loading-spinner {
    height: 75px;
    width: 75px;
    margin: auto;
    position: relative;
    bottom: 2rem;
    pointer-events: none;
  }

  .notification-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 12px;
    border-radius: 5px;
    background-color: #fff;
    border: solid 2px #ededed;
    opacity: 0.85;

    &:hover {
      background-color: rgb(255, 255, 255, 0.8);
      opacity: 1;
    }
  }
  .notification-message__text {
    display: flex;
    flex-direction: column;
  }
  .notification-message__text__title {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62em;
    letter-spacing: 0.27px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 264px;

    white-space: initial;
    max-height: 4.86em;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .notification-message__text__date {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.25px;
    color: rgba(17, 17, 17, 0.7);
  }

  .notification-message__indicator {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .notification-message__indicator__dot {
    width: 10px;
    height: 10px;
    background-color: #4fc3f7;
    border-radius: 100%;
  }
`;

export default function NotificationTray() {
  const user = useUser();
  const websocket = useWebsocket();
  const [trayIsOpen, setTrayIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(false);

  function toggleOpen() {
    setTrayIsOpen((prev) => !prev);
  }

  function requestNotifications() {
    websocket?.emit(REQUEST_NOTIFICATION_LIST_ENUM);
  }

  function parseNotificationList({
    newNotifications,
    notifications,
  }: {
    newNotifications: boolean;
    notifications: [];
  }) {
    setNewNotifications(newNotifications);
    setNotifications(notifications);
  }

  function handleNotificationClick(notificationId: string) {
    websocket?.emit(UPDATE_NOTIFICATION_READ_STATUS_ENUM, notificationId);
    websocket?.emit(UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM);
  }

  useEffect(() => {
    if (websocket && !!user?.email) {
      requestNotifications();
      websocket?.on(NEW_NOTIFICATION_ENUM, requestNotifications);
      websocket?.on(NOTIFICATION_LIST_ENUM, parseNotificationList);

      return () => {
        websocket?.off(NEW_NOTIFICATION_ENUM, requestNotifications);
        websocket?.off(NOTIFICATION_LIST_ENUM, parseNotificationList);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, websocket]);

  return (
    <Container trayIsOpen={trayIsOpen}>
      <Dropdown tag="div" toggle={toggleOpen} isOpen={trayIsOpen}>
        <DropdownToggle
          color="standard-quartenary"
          className="notification-toggle"
          aria-expanded={trayIsOpen}
          onClick={() => {
            trackEvent({
              name: `button_notification-toggle-navbar-top_click`,
            });
          }}
        >
          <div className={newNotifications ? "pending-notifications" : "observed-notifications"}>
            <Bell size={16} />
            {!trayIsOpen && newNotifications && <div className="unread-count" />}
          </div>
        </DropdownToggle>
        <DropdownMenu end style={{ marginTop: "6px" }}>
          <div className="notification-tray">
            <div className="notification-tray__heading">Notifications</div>
            <div className="notification-tray__list">
              <NotificationList notifications={notifications} handleNotificationClick={handleNotificationClick} />
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </Container>
  );
}
