import { createContext, PropsWithChildren, useContext } from "react";
import { FeatureFlag, getDefaultFeatureFlags } from "./feature-flags";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { ACCELERATE_APIS } from "../api/config";
import { useQuery } from "react-query";

interface FeatureFlagsContextValue {
  featureFlags: Record<FeatureFlag, boolean>;
  loading: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue | undefined>(undefined);

export type FeatureFlagsProviderProps = PropsWithChildren<{
  initialFlags?: Record<string, boolean>; // Optional initial feature flags for storybook
}>;

export function FeatureFlagsProvider({ children, initialFlags = {} }: FeatureFlagsProviderProps) {
  const initialFlagsProvided = Object.keys(initialFlags).length > 0;
  const initialFeatureFlagsState: Record<FeatureFlag, boolean> = initialFlagsProvided
    ? initialFlags
    : getDefaultFeatureFlags();

  const { data = { featureFlags: initialFeatureFlagsState }, isLoading } = useQuery<
    { featureFlags: Record<FeatureFlag, boolean> },
    Error
  >(["feature-flags"], async () => {
    const token = await requestAccessToken(
      ACCELERATE_APIS.apollo.scopes, // We are standardizing on this app reg
    );
    const res = await fetch("/api/feature-flags", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await res.json();
  });

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags: data.featureFlags, loading: isLoading }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

// Hook for easy access to the context
export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error("useFeatureFlags must be used within a FeatureFlagsProvider");
  }
  return context;
};
