import { createContext, PropsWithChildren, useState } from "react";
import { Toast, ToastHeader } from "reactstrap";
import { useTimeoutToggle } from "../../hooks/use-timeout-toggle";
import { ErrorAlert } from "../../views/profile/teams/error-alert";
import { SuccessModal, SuccessModalProps } from "../modals/success-modal";

type ModalProps = Omit<SuccessModalProps, "toggle" | "isOpen">;
interface ToastContextType {
  displayToast: (message: string) => void;
  displayError: (error?: unknown) => void;
  displaySuccessModal: (body: ModalProps) => void;
}

export const ToastContext = createContext<ToastContextType | undefined>(undefined);

function SnackBar({ children }: PropsWithChildren) {
  return (
    <div data-testid="snack-bar" className="d-flex justify-content-evenly">
      <Toast
        className="shadow-sm position-fixed w-auto text-center rounded-2 z-3"
        style={{
          bottom: "40px",
        }}
      >
        <ToastHeader tag="span" data-testid="toast-message">
          {children}
        </ToastHeader>
      </Toast>
    </div>
  );
}

export function ToastProvider({ children }: PropsWithChildren) {
  const { show: showSnackBar, toggle: setShowSnackBar } = useTimeoutToggle(false);
  const [message, setMessage] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [body, setBody] = useState<ModalProps>({});
  const [errorMessage, setErrorMessage] = useState("");

  const displayToast = (message: string) => {
    setMessage(message);
    setShowSnackBar(true);
  };
  const displaySuccessModal = (body: ModalProps) => {
    setBody(body);
    setShowSuccessModal(true);
  };

  const displayError = (error?: unknown) => {
    setErrorMessage(error as string);
    setShowError(true);
  };

  const closeError = () => {
    setShowError(false);
  };

  return (
    <ToastContext.Provider value={{ displayToast, displayError, displaySuccessModal }}>
      {children}
      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          toggle={() => setShowSuccessModal(!showSuccessModal)}
          title={body.title}
          message={body.message}
          closeButtonText={body.closeButtonText}
          name={body.name}
          showConfirmationButton={body.showConfirmationButton}
          confirmationButtonText={body.confirmationButtonText}
          confirmationButtonClick={() => {
            setShowSuccessModal(!showSuccessModal);
            body.confirmationButtonClick && body.confirmationButtonClick();
          }}
        >
          {body.children}
        </SuccessModal>
      )}
      {showSnackBar && message && <SnackBar>{message}</SnackBar>}
      {showError && <ErrorAlert onClose={closeError} errorMessage={errorMessage} />}
    </ToastContext.Provider>
  );
}
