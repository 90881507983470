import { useState, Fragment } from "react";
import Step1Form from "./Step1";
import { ConfirmResults, SUBMISSIONTYPES } from "../../../components/FAQComponents/confirm-results";
import { useMutation } from "@apollo/client";
import { GET_USER_QUESTIONS, ADD_QUESTION, UPDATE_QUESTION, GET_QUESTION } from "../../../api/faq/gql";
import { useIsAuthenticated } from "../../../hooks/use-is-authenticated";

AskAQuestionWizard.defaultProps = {
  step: 1,
  editMode: false,
  onComplete: () => {},
  onStepChange: () => {},
  onDirty: () => {},
  id: "",
  title: "",
  body: "",
  tags: [],
  tagOptions: [],
};

export default function AskAQuestionWizard({
  step,
  editMode,
  onComplete,
  onStepChange,
  onDirty,
  id,
  title,
  body,
  tags,
  tagOptions,
}) {
  const user = useIsAuthenticated();
  const [currentStep, setCurrentStep] = useState(step);
  const [step1Data, setStep1Data] = useState({
    title,
    body,
    tags,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState();
  const QUESTION_QUERY_VARIABLES = {
    hideInReview: false,
    hideApproved: true,
    hideRejected: true,
    user: user?.email,
  };

  const [AddQuestion] = useMutation(ADD_QUESTION, {
    onCompleted() {
      goToNextStep();
    },
    onError: handleError,
    refetchQueries: [
      {
        query: GET_USER_QUESTIONS,
        variables: QUESTION_QUERY_VARIABLES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [UpdateQuestion] = useMutation(UPDATE_QUESTION, {
    onCompleted() {
      goToNextStep();
    },
    onError: handleError,
    refetchQueries: [{ query: GET_QUESTION, variables: { questionId: id } }],
    awaitRefetchQueries: true,
  });

  function goToNextStep() {
    setIsSubmitting(false);
    setCurrentStep((currentStep) => currentStep + 1);
    onStepChange((currentStep) => currentStep + 1);
  }

  async function handleStep1Submit({ title, body, tags }) {
    setStep1Data({ title, body, tags, id });
    setRequestError();
    setIsSubmitting(true);
    let result;
    const data = { title, body, tags };
    if (editMode) {
      result = await UpdateQuestion({
        variables: { data: { ...data, id } },
      });
    } else {
      result = await AddQuestion({
        variables: { data },
      });
    }

    return result;
  }

  function handleError(error) {
    setIsSubmitting(false);
    setRequestError(`Error: ${error.message}`);
    goToNextStep();
  }

  return (
    <Fragment>
      <div style={{ display: currentStep === 1 ? "block" : "none" }}>
        <Step1Form
          {...step1Data}
          editMode={editMode}
          onDirty={onDirty}
          onSubmit={handleStep1Submit}
          isSubmitting={isSubmitting}
          tagOptions={tagOptions}
        />
      </div>
      <div style={{ display: currentStep === 2 ? "block" : "none" }}>
        <ConfirmResults
          submissionType={editMode ? SUBMISSIONTYPES.EDIT : SUBMISSIONTYPES.ADD}
          onHide={onComplete}
          componentType="question"
          failed={!!requestError}
          customMessage={requestError}
        />
      </div>
    </Fragment>
  );
}
