import styled from "@emotion/styled";
import { Remove24 } from "@bphxd/ds-core-react/lib/icons";
import { Trash24 as DeleteIcon, Ask48 as AskIcon, Check16 as TickIcon } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "../button";
import { Box } from "../box";

const Container = styled.div`
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 72px;
    height: 72px;
    background-color: ${({ failed }) => (failed ? "#ffedf0" : "#e6fff2")};
    border-radius: 50%;
    margin: auto;
    margin-top: -40px;
  }
  .tick-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: ${({ failed }: { failed: string }) => (failed ? "#ff7591" : "#2ee68c")};
    border-radius: 50px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    margin-top: 40px;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: center;
    color: #111;
  }
  .message {
    margin: 24px 0 40px 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.2px;
    text-align: center;
    color: rgba(17, 17, 17, 0.7);
  }
`;

export const SUBMISSIONTYPES = {
  ADD: "ADD",
  EDIT: "EDIT",
  DELETE: "DELETE",
};

export const TESTID_ENUMS = {
  [`confirm-${SUBMISSIONTYPES.ADD}-dialog`]: `faq-submission-result-${SUBMISSIONTYPES.ADD}-dialog`,
  [`confirm-${SUBMISSIONTYPES.EDIT}-dialog`]: `faq-submission-result-${SUBMISSIONTYPES.EDIT}-dialog`,
  [`confirm-${SUBMISSIONTYPES.DELETE}-dialog`]: `faq-submission-result-${SUBMISSIONTYPES.DELETE}-dialog`,
  [`confirm-${SUBMISSIONTYPES.ADD}-button`]: `close-faq-submission-result-${SUBMISSIONTYPES.ADD}-modal-button`,
  [`confirm-${SUBMISSIONTYPES.EDIT}-button`]: `close-faq-submission-result-${SUBMISSIONTYPES.EDIT}-modal-button`,
  [`confirm-${SUBMISSIONTYPES.DELETE}-button`]: `close-faq-submission-result-${SUBMISSIONTYPES.DELETE}-modal-button`,
  title: "faq-submission-result-title",
  message: "faq-submission-result-message",
  icon: "faq-submission-result-icon",
};

export type ConfirmResultsProps = {
  customTitle?: string;
  customMessage?: string;
  submissionType: string;
  componentType: string;
  failed: boolean;
  onHide: () => void;
};

export function ConfirmResults({
  customTitle,
  customMessage,
  submissionType,
  componentType = "answer",
  failed,
  onHide,
}: ConfirmResultsProps) {
  let title, message;
  if (submissionType === SUBMISSIONTYPES.ADD) {
    title = `Your ${componentType} ${failed ? "failed to submit" : "was submitted successfully"}`;
  }
  if (submissionType === SUBMISSIONTYPES.EDIT) {
    title = `Your changes ${failed ? "failed to be saved" : "were saved successfully"}`;
  }
  if (submissionType === SUBMISSIONTYPES.DELETE) {
    title = `Your ${componentType} ${failed ? "failed to be deleted" : "was deleted successfully"}`;
    message = failed
      ? "Please try again later."
      : "The digital engineering team won’t be able to view and answer it anymore.";
  }

  if (customTitle) {
    title = customTitle;
  }

  if (customMessage) {
    message = customMessage;
  }

  return (
    <Container data-testid={TESTID_ENUMS[`confirm-${submissionType}-dialog`]} failed={failed ? "failed" : ""}>
      <div className="icon" data-testid={TESTID_ENUMS.icon}>
        {(submissionType === SUBMISSIONTYPES.EDIT || submissionType === SUBMISSIONTYPES.ADD) && (
          <AskIcon width="48" height="48" />
        )}
        {submissionType === SUBMISSIONTYPES.DELETE && <DeleteIcon width="37" height="38" />}
        <div className="tick-icon">{failed ? <Remove24 /> : <TickIcon />}</div>
      </div>

      <div className="title" data-testid={TESTID_ENUMS.title}>
        {title}
      </div>
      <div className="message" data-testid={TESTID_ENUMS.message}>
        {message}
      </div>
      <Box justify="center">
        <Button
          type="button"
          data-testid={TESTID_ENUMS[`confirm-${submissionType}-button`]}
          onClick={onHide}
          name={TESTID_ENUMS[`confirm-${submissionType}-button`].replace("-button", "")}
          level="primary"
          theme="darker"
          rounded="pill"
          size="md"
        >
          Done
        </Button>
      </Box>
    </Container>
  );
}
