import { Button, bpCoreButtonProps } from "./button";
import { isUrlInternal } from "./Link";
import { ExternalLink24 } from "@bphxd/ds-core-react/lib/icons";
import { useLocation } from "../hooks/use-location";
import { useEffect } from "react";

export type LinkButtonProps = React.PropsWithChildren<
  {
    href: string;
    name?: string;
    hideExternalLinkIcon?: boolean;
  } & bpCoreButtonProps
>;

export function LinkButton({
  href,
  name,
  hideExternalLinkIcon = false,
  children,
  trackingEventProps,
  onClick,
  ...buttonProps
}: LinkButtonProps) {
  const { router } = useLocation();
  const newWindow = isUrlInternal(href) ? {} : { target: "_blank", rel: "noreferrer" };

  function handleClick(e: any) {
    e.preventDefault();
    router.push(href);

    if (onClick) {
      onClick(e);
    }
  }

  useEffect(() => {
    if (isUrlInternal(href)) {
      router.prefetch(href);
    }
  }, [href]);
  return (
    <Button
      href={href}
      data-testid={name}
      name={name}
      onClick={handleClick}
      {...newWindow}
      {...buttonProps}
      trackingEventProps={trackingEventProps}
    >
      {isUrlInternal(href) || hideExternalLinkIcon ? (
        children
      ) : (
        <div className="d-flex align-items-start">
          <div>{children}</div>

          <ExternalLink24 data-testid="external-link-icon" />
        </div>
      )}
    </Button>
  );
}
