import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import { trackEvent } from "../../utils/event-tracker";

import { Form, Row, Col } from "reactstrap";
import { Modal } from "../../components/modal";
import { FormGroup, Label } from "../../components/form-group";
import Input from "../../components/Input";
import { Input as SwitchInput } from "reactstrap";
import { Search24 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "../../components/button";
import Select from "../../components/Select";

export const DATA_TESTIDS = {
  nameInput: "tag-name",
  typeInput: "tag-type",
  descriptionInput: "tag-description",
  khOverviewInput: "tag-kh-overview",
  highlighted: "tag-highlighted",
  submitButton: "tag-submit-button",
  iconButton: "tag-icon-button",
};

function buildIcon(iconB64) {
  if (!iconB64) {
    return null;
  }
  const svgStr = Buffer.from(iconB64, "base64").toString();
  const svg = new Blob([svgStr], { type: "image/svg+xml" });
  return URL.createObjectURL(svg);
}

const FileUploader = ({ onFileSelect }) => {
  const fileInput = useRef(null);

  const toBase64 = (file, resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  };

  const handleFileInput = (e) => {
    // handle validations
    toBase64(e.target.files[0], (result) => {
      const iconData = result.split(",")[1];
      onFileSelect(buildIcon(iconData), iconData);
    });
  };

  return (
    <div className="mb-4">
      <input type="file" ref={fileInput} hidden onChange={handleFileInput} accept=".svg"></input>
      <Button
        data-testid={DATA_TESTIDS.iconButton}
        name="create-new-tag"
        Icon={Search24}
        level="tertiary"
        theme="standard"
        rounded="pill"
        size="sm"
        onClick={() => fileInput.current && fileInput.current.click()}
      >
        Icon
      </Button>
    </div>
  );
};

export default function AddATagModal({
  show,
  onHide,
  existingTags,
  onSubmit,
  loading,
  tagTypeOptions,
  tagName,
  tagType,
  description,
  kHubOverviewPage,
  icon,
  highlighted,
  edit = false,
}) {
  const [isDirty, setIsDirty] = useState(false);
  const iconUrl = buildIcon(icon);
  const [iconChangedUrl, setIconChangedUrl] = useState(iconUrl);
  const [iconData, setIconData] = useState(icon);

  const schemaObject = {
    tagName: yup
      .string()
      .strict()
      .trim()
      .required("Required")
      .notOneOf(existingTags?.map(({ tagName }) => tagName), "Tag must not already exist"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    values: {
      tagName,
      tagType,
      description,
      highlighted,
      kHubOverviewPage,
      icon,
    },
  });

  const fileSelected = (iconUrl, iconData) => {
    setIconData(iconData);
    setIconChangedUrl(iconUrl);
  };

  function serializeValues(data) {
    if (iconData) {
      data.icon = iconData;
    }
    onSubmit(data);
    setIconData(null);
    setIconChangedUrl(null);
  }

  useEffect(() => {
    if (show) {
      trackEvent({
        name: `add-tag-modal-opened`,
      });
    } else {
      setIconChangedUrl(null);
    }
  }, [show]);

  useEffect(() => {
    const subscription = watch(() => setIsDirty(true));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={isDirty}
      onClose={() => {
        setIsDirty(false);
        onHide();
        setIconChangedUrl(null);
      }}
      header={edit ? "Edit existing tag" : "Create a new tag"}
    >
      <Form onSubmit={handleSubmit(serializeValues)}>
        <Row>
          <Col xs="2">
            <FileUploader onFileSelect={fileSelected} />
          </Col>
          <Col xs="2">
            <FormGroup className="without-margin-top">
              <Label htmlFor="icon"></Label>
              <Controller
                name="icon"
                control={control}
                render={() => (
                  <>
                    {iconChangedUrl && (
                      <img src={iconChangedUrl} style={{ height: "32px", width: "32px" }} alt="tag icon" />
                    )}
                    {iconUrl && !iconChangedUrl && (
                      <img src={iconUrl} style={{ height: "32px", width: "32px" }} alt="tag icon" />
                    )}
                  </>
                )}
              />

              <Col xs="8"></Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="8">
            <FormGroup className="without-margin-top">
              <Label htmlFor="tagName">Name</Label>
              <Controller
                name="tagName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="tagName"
                    data-testid={DATA_TESTIDS.nameInput}
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup className="without-margin-top">
              <Label htmlFor="tagType">Type</Label>
              <Controller
                name="tagType"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    data-testid={DATA_TESTIDS.typeInput}
                    invalid={!!error}
                    placeholder="Select tag type"
                    options={tagTypeOptions}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup className="without-margin-top">
              <Label htmlFor="description">Description</Label>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="description"
                    invalid={!!error}
                    data-testid={DATA_TESTIDS.descriptionInput}
                    type="text"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup className="without-margin-top">
              <Label htmlFor="kHubOverviewPage">
                KnowledgeHub Overview page UUID (i.e. the UUID in the page URL that follows <code>/knowledgehub/</code>)
              </Label>
              <Controller
                name="kHubOverviewPage"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="kHubOverviewPage"
                    invalid={!!error}
                    data-testid={DATA_TESTIDS.khOverviewInput}
                    type="text"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="2">
            <FormGroup switch className="form-check-alt form-check-reverse">
              <Controller
                name="highlighted"
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <>
                    <SwitchInput
                      id="highlighted"
                      name="highlighted"
                      data-testid={DATA_TESTIDS.highlighted}
                      type="switch"
                      onBlur={onBlur}
                      invalid={!!error}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                    <Label check for="highlighted">
                      Highlight
                    </Label>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col xs="10"></Col>
        </Row>
        <Row className="align-items-center">
          <Col xs="8"></Col>
          <Col xs="4">
            <div className="text-end">
              <Button
                data-testid={DATA_TESTIDS.submitButton}
                name="submit-new-tag"
                type="submit"
                disabled={!isValid}
                loading={isSubmitting || loading}
                level="primary"
                theme="darker"
                rounded="pill"
              >
                {edit ? <>Submit</> : <>Add new tag</>}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

AddATagModal.defaultProps = {
  show: false,
  onHide: () => {},
  onSubmit: () => {},
  existingTags: [],
  loading: false,
};
