import { useEffect, useState } from "react";

import { trackEvent } from "../../../utils/event-tracker";

import { Modal } from "../../../components/modal";
import AskAQuestionWizard from "./Wizard";

type AskQuestionModalProps = {
  show: boolean;
  onHide: () => void;
  tagOptions: { value: string; label: string }[];
  editMode?: boolean;
};
export function AskAQuestionModal({ show, onHide, tagOptions, editMode, ...data }: AskQuestionModalProps) {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (show) {
      trackEvent({
        name: `${editMode ? "edit" : "ask-a"}-question-modal-opened`,
      });
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={currentStep < 2}
      onClose={() => {
        onHide();
      }}
      header={(editMode ? "Edit your" : "Ask a") + " question"}
    >
      <AskAQuestionWizard
        onDirty={() => {}}
        onStepChange={setCurrentStep}
        tagOptions={tagOptions}
        onComplete={onHide}
        editMode={editMode}
        {...data}
      />
    </Modal>
  );
}
