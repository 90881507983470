import { PropsWithChildren, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Row, Col } from "reactstrap";
import { Toast } from "../components/toast";
import { NotificationType, MapEventToNotification } from "../components/NotificationTray/notification-mapper";
import { Footer } from "../components/footer";
import { HelpAndSupportFormModal } from "../forms/helpAndSupport/modal";
import { useWebsocket } from "../hooks/use-websocket";
import { MegaMenu2 } from "../navigation/mega-menu";
import { NavbarTop } from "../navigation/navbar-top";
import ExceptionPage from "../views/exception/exception-page";
import { useAppActions, useIsHelpAndSupportModalOpen, useToasts } from "../hooks/use-app-store";
import { useLocation } from "../hooks/use-location";

export const PageLayout: React.FC<PropsWithChildren> = (props) => {
  const websocket = useWebsocket();
  const location = useLocation();

  const { setToasts, setIsHelpAndSupportModalOpen } = useAppActions();

  const toasts = useToasts();
  const isHelpAndSupportModalOpen = useIsHelpAndSupportModalOpen();

  function handleNewNotification(notification: NotificationType) {
    const { id, title } = MapEventToNotification(notification);
    appendToast({ id, title });
  }

  function appendToast({ id, title = "You have a new notification" }: { id: string; title?: string }) {
    if (id) {
      setToasts([
        ...toasts,
        <Toast autohide key={id}>
          {title}
        </Toast>,
      ]);
    }
  }

  useEffect(() => {
    if (websocket) {
      websocket?.on("NEW_NOTIFICATION", handleNewNotification);
      return () => {
        websocket?.off("NEW_NOTIFICATION", handleNewNotification);
      };
    }
  }, [websocket]);

  const errorHandler = (error: any, info: any) => {
    console.error({ error, info });
  };

  return (
    <>
      <HelpAndSupportFormModal show={isHelpAndSupportModalOpen} onHide={() => setIsHelpAndSupportModalOpen(false)} />
      <NavbarTop />
      <MegaMenu2 />
      <div className="app-container position-relative">
        <Row className="main-content">
          <Col className={`col main px-0 py-0`} role="main">
            <ErrorBoundary
              key={location.pathname}
              fallbackRender={() => <ExceptionPage setIsHelpAndSupportModalOpen={setIsHelpAndSupportModalOpen} />}
              onError={errorHandler}
            >
              {props.children}
            </ErrorBoundary>
          </Col>
          {toasts.length > 0 && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: "2rem",
              }}
            >
              {toasts}
            </div>
          )}
        </Row>
      </div>
      <Footer />
    </>
  );
};
