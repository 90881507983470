import { useRouter } from "next/router";

export function useLocation() {
  const router = useRouter();
  const search = { ...router.query };
  let routePath = "/";
  if (router.query.all) {
    routePath += (router.query.all as string[]).join("/");
  }
  delete search.all;
  return {
    router,
    pathname: router.asPath.split("?")[0],
    search,
    routePath,
  };
}
